import * as React from 'react';
import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/web';

export default createGatsbyPageComponent('Page');

export const query = graphql`
  query PageQuery($id: ID!) {
    platform {
      page(id: $id) {
        id slug path name title pretitle subtitle
        content { text }
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
        intro { text }
        outro { text }
        orderNumber
        family {
          id name slug path subtitle
        }
        layout {
          id name slug component 
        }
        videos {
          id name url
          thumbnail { id url static { childImageSharp { gatsbyImageData } } }
        }
        trend {
          id name slug path outlineNumber color { value }
          sentimentAnalyses {
            id name slug
            images {
              id slug
              image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            }
            type { id name slug label color { id name value } }
            observations { 
              id name slug 
              description { text }
              topics {
                name slug path outlineNumber color { value }
              }
              subTopics {
                name slug path outlineNumber color { value }
              }
            }
          }
        }
        trends {
          name slug path outlineNumber color { value }
        }
        connectedTrends {
          id name slug path outlineNumber color { value }
        }
        connectedTopics {
          id name slug path outlineNumber color { value }
        }
        connectedSubTopics {
          id name slug path outlineNumber color { value }
        }
        connectedPages {
          id name slug path type { name }
        }
        people {
          id name slug path
          organisation { id name }
          position
          photo { id url }
        }
        embeds {
          citations {
            slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
          }
          trends {
            name slug path outlineNumber color { slug value }
            openGraph {
              title description { plain }
              image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            }
          }
          topics { 
            id path outlineNumber slug name color { id value } 
            openGraph {
              title description { plain }
              image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            }
          }
          subTopics { 
            id path outlineNumber slug name color { id value } 
            openGraph {
              title description { plain }
              image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            }
          }
          events {
            id slug path 
            name
            startDate
            endDate
            description { text }
            color { id value darkValue }
            images { image { id url width height } }
            banner {
              id name slug title description { text } alternateText
              image { 
                id url width height 
              }
            }
            links { id url name label isDownload page { path } }
            sessions { 
              id name __typename 
              startDate endDate
              icsPath(base: "/downloads")
              description { text }
              room { id name shortName }
              color { id veryLightValue lightValue value darkValue }
              presenters { 
                id name 
                bio { html }
                photo { url }
                openGraph { title description { plain } image { thumbnails { full { url } } } }
              }
              group { id name shortName }
            }
          }
          pages {
            id name slug path
            openGraph {
              title description { plain }
              image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            }
          }
          people {
            id name slug path
            organisation { id name }
            position
            photo { id url }
          }
          imageAssets {
            id
            slug
            image {
              id url width height static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
            }
          }
        }
        featurePages {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { plain } }
          summary { text }
          intro { text }
          orderNumber
          layout { name slug }
          videos {
            id name url
            thumbnail { id url static { childImageSharp { gatsbyImageData } } }
          }
          banner {
            name slug alternateText
            thumbnail { 
              id url width height static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
            }
          }
        }
        marqueePages {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { plain } }
          summary { text }
          intro { text }
          orderNumber
          videos {
            id name url
            thumbnail { id url static { childImageSharp { gatsbyImageData } } }
          }
          layout { name slug }
          banner {
            name slug alternateText
            thumbnail { 
              id url width height static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
            }
          }
        }
        children {
          __typename id name path slug title subtitle pretitle 
          summary { text }
          openGraph { 
            image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            description { text } 
          }
          family {
            id name slug path subtitle
          }
          intro { text }
          orderNumber
          banner {
            name slug alternateText
            thumbnail { 
              id url width height static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
            }
          }
        }
        feedEntries {
          __typename _schema { label pluralLabel }
          id name slug __typename url published websiteName summary { text }
          openGraph { image { url static { childImageSharp { gatsbyImageData } } } }
        }

        banner {
          id name slug title description { text } alternateText
          image { 
            id url width height static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
          }
        }
      }
    }
  }
`;